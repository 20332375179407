<template>
  <EmbeddedModal
    @close="() => (modal.open = false)"
    :open="modal.open"
    :title="modal.title"
    :description="modal.description" />

  <div
    v-show="!modal.open"
    class="flex h-screen flex-col overflow-hidden bg-white text-black dark:bg-neutral-800 dark:text-white">
    <div
      class="h-fit border-b-[5px] border-[#EEEEEE]/[82px] p-7 dark:border-[1px] dark:border-[#262630]">
      <div>
        <p class="text-xs font-medium">
          {{ $t('Embedded.programs') }}
          <span class="font-normal text-neutral-700">
            {{ $t('Embedded.by') }} {{ programs?.[0]?.radio?.name }}</span
          >
        </p>
        <h3 class="text-left text-[21px] font-bold text-black dark:text-white">
          {{
            $t('Embedded.all-programs-from', {
              radio: programs?.[0]?.radio?.name
            })
          }}
        </h3>
      </div>

      <div
        class="my-4 h-0 w-full border border-[#EEEEEE] dark:border-[#262630]"></div>
    </div>
    <div
      class="relative flex h-[400px] flex-1 flex-col bg-[#F6F6F6D1]/[82%] px-[29px] py-6 pb-[8.5rem] dark:bg-[#0B0B11BF]/[75] lg:pb-[7.5rem]">
      <div
        class="custom-scroll flex h-full flex-col gap-6 overflow-auto pr-[12px]">
        <EmbeddedListenLive
          v-if="!isLive && $route.query.live === 'true'"
          :title="programs?.[0].radio?.name"
          :radio-id="programs?.[0].radio?.id" />

        <div class="flex flex-col">
          <EmbeddedProgramCard
            v-for="(program, index) in programs"
            :i="index"
            :key="index"
            :handle-modal="handleModal"
            :primary-color="primaryColor"
            :program="program" />
        </div>
      </div>
      <Pagination
        v-model:current="page"
        :total="nbPages"
        path="" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { Ref } from 'vue'
import { translateItems } from '@/composables/use-translations'

const route = useRoute()
const { isLive } = usePlayerState()
const { primaryColor } = useEmbedded()

const { locale } = useI18n()

const props = withDefaults(
  defineProps<{
    elementsPerPage?: number
  }>(),
  {
    elementsPerPage: 10
  }
)

const modal = reactive({
  open: false,
  title: '',
  description: ''
})

const handleModal = (i: number) => {
  if (!programs.value) return
  const program = programs.value[i]
  modal.open = true
  modal.title = program.title || ''
  modal.description = program.description || ''
}

const page = ref(parseInt(route.query.page as string) || 1)

const radioId = route.params.radio

const responseProgram = await useApiFetch<Program>(`/program`, {
  fields:
    'id,image,translations.*,radio.name, radio.id,categories_boa.id,categories_boa.category_boa_id.translations.*', //''
  filter: JSON.stringify({
    radio: {
      _eq: radioId
    }
  }),
  limit: props.elementsPerPage,
  page: page,
  meta: 'filter_count',
  sort: ['-date_created']
})

const nbPages = computed(() =>
  Math.round(
    (responseProgram.data.value?.meta?.filter_count || 0) /
      props.elementsPerPage
  )
)

const programs: Ref<Program[] | undefined> = computed(() =>
  translateItems(responseProgram.data.value?.data || [], {
    lang: locale.value,
    stripHTMLTags: false,
    limitChar: 0
  })
)
</script>
